import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'contracts',
    prefix: 'order.customer_contracts',
    prefix_state: 'order_customer_contracts',
  },
  {
    default_query: {
      sort: "-updated_at",
      [`filter[own]`]: 1,
      include: 'status,customer',
    },
    detail_query: {
      append: "items,freight_charge,freight_charge_paid,freight_charge_unsolved,freight_charge_debited,freight_charge_outstanding,discount",
      include: "orders,customer"
    },
    default_detail: {
      status_id: '',
      customer: {},
      items: []
    }
  },
).store()

export default resource
