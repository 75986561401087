import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'orders',
    prefix: 'order.customer_orders',
    prefix_state: 'order_customer_orders',
  },
  {
    default_query: {
      sort: "-created_at",
      include: 'type,status',
      append: 'first_item',
      'filter[own]': 1,
    },
    detail_query: {
      append: 'related_trackings',
      include: 'type,status,customer',
    },
    default_detail: {
      id: '',
      status_id: '',
      customer_id: '',
      object: {
        name: ''
      },
      related_trackings: [],
    }
  }
).store()

export default resource
