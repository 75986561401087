import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'accounting',
    resource: 'wallets_users',
    prefix: 'accounting.customer_wallets',
    prefix_state: 'accounting_customer_wallets',
  },
  {
    default_query: {
      sort: "-updated_at",
      include: 'object',
      [`filter[walletable_id]`]: '',
    },
    detail_query: {
      include: 'object',
    },
    default_detail: {
      walletable_id: '',
      currency_id: '',
      walletable_type: ''
    }
  }
).store()

export default resource
