export default [
  {
    _name: "TSidebarNavItem",
    name: "User info",
    to: "/customer",
    icon: "cil-user",
    exact: true
  },
  {
    _name: "TSidebarNavItem",
    name: "Orders",
    to: "/customer/orders",
    icon: "cil-library-books",
  },
  {
    _name: "TSidebarNavItem",
    name: "Contract",
    to: "/customer/contract",
    icon: "cil-list-rich",
  },
  {
    _name: "TSidebarNavItem",
    name: "Wallet",
    to: "/customer/wallets",
    icon: "cil-money",
  },
  {
    _name: "TSidebarNavItem",
    name: "Address",
    to: "/customer/address",
    icon: "cil-map",
  },
]
